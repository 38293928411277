import React, { useState, useEffect, useContext } from 'react'
import Layout from '../components/layout'
// import { gsap } from 'gsap'
import { AuthContext } from '../firebase'
import { navigate } from 'gatsby'
import Seo from "../components/seo"


const Verify = ({ location }) => {

  const userInit = {myUser: ""}
  const { authUser }  = useContext(AuthContext) || userInit
  const { user } = authUser || userInit

  useEffect(() => {
    if(user){
      navigate("/app/dashboard")
    }
  },[])


  const [signin] = useState((typeof window === 'undefined') ? "" : location.state.signin)

  return (
    <Layout>
      <Seo title="T&eacute;l&eacute;m&eacute;decine - Consultation en ligne - Haiti "  />
      <div className="sign-contain">
        <div className="sign-element mycard">
          <h3 className="center color-4">
            Compte cr&eacute;&eacute; avec succ&egrave;s
          </h3>
          <p>
            {!signin ?"Votre compte a été créé avec succès." : "Votre adresse email n'est pas encore vérifiée"} Un email a été envoyé à votre boite aux lettres pour vérification. Veuillez cliquer sur le lien dans votre email afin de compléter la validation. {signin && "Veuillez utiliser le lien dans le mail le plus récent."} Vous pouvez fermer cette page apr&egrave;s valitation
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Verify
